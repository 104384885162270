<template>
  <div class="mt-content">
    <div class="mt-iframe">
      <iframe
        :src="'https://e.dianping.com/dz-open/merchant/auth?' + params"
        style="width: 100%; height: 100%"
      ></iframe>
    </div>

    <el-dialog
      title="授权成功"
      :visible="resultVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      center
    >
      <div>
        <div>
          <div>
            <div class="font-size-18 mb-20">
              请选择您要绑定的门店id进行复制
              <span class="mt-30 text-center" style="color: red">
                （请确认您已复制了门店id再关闭页面）
              </span>
            </div>
            <div class="mb-20" v-for="(item, index) in hotelList" :key="index">
              <div>{{ item.shopname }}<span v-if="item.branchname">（{{ item.branchname }}）</span></div>
              
              <div>地址：{{ item.cityname }} {{ item.shopaddress }}</div>
              <div>
                门店id：{{ item.open_shop_uuid }}
                <el-button
                  size="small"
                  type="primary"
                  class="ml-30"
                  @click.stop="handleCopy(item)"
                  >复制</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div slot="footer">
        <el-button size="small" @click="closePage"> 关闭页面 </el-button>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { objectToParams } from "@/utils";
import { get_meiTuanSession } from "@/api/hotel";

export default {
  data() {
    return {
      params: "",
      result: null,
      hotelList: [],
      resultVisible: false,
      // exampleData: {
      //   "message":"open_sdk_auth_success",
      //   "auth_code":"qwertyuiopasdfghjklzxcvbnm",
      //   "state":"teststate"
      // }
    };
  },
  mounted() {
    document.title = "美团商户授权";
    this.params = objectToParams(this.$route.query);
    window.addEventListener("message", (e) => {
      // 可通过e对象，自行获取相关参数
      console.log(e);
      if (e.data.auth_code) {
        const data = e.data;
        this.result = data;
        console.log("接收到的消息", this.result);
        get_meiTuanSession(this.result).then((res) => {
          this.hotelList = res || [];
          this.resultVisible = true;
        });
      }
    });
  },
  methods: {
    handleCopy(item) {
      let target = document.createElement("input");
      target.value = item.open_shop_uuid; // 复制的内容 美团商户id
      document.body.appendChild(target);
      target.select();
      document.execCommand("Copy");
      this.$message.success("复制成功");
    },
    // closePage() {
    //   window.location.href = "about:blank";
    //   window.close();
    // },
    closePage() {
      if (
        navigator.userAgent.indexOf("Firefox") != -1 ||
        navigator.userAgent.indexOf("Chrome") != -1
      ) {
        window.location.href = "about:blank";

        window.close();
      } else {
        window.opener = null;

        window.open("", "_self");

        window.close();
      }
    },
  },
};
</script>

<style lang="scss">
body,
html,
#app,
.mt-content,
.mt-iframe {
  height: 100%;
}
</style>